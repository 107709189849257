<template>
    <div v-for="category in userDocData" v-bind:key="category.id" class="row mt-5">
        <h3 v-if="category.documents.length !== 0" class="mb-5">
            <span v-if="i18n && i18n.locale === 'fr'">{{ category.label }}</span>
            <span v-if="i18n && i18n.locale === 'en'">{{ category.label_en }}</span>
        </h3>

        <div class="drive-scroll">
            <template v-for="document in category.documents" v-bind:key="document.id">
                <div class="col-3 mb-4 elem" v-if="document.user_types.includes(this.$store.getters.currentUser.user.userprofile.user_type.id)">
                    <div class="card h-100">
                        <div class="card-body d-flex justify-content-center text-center flex-column p-1">
                            <a href="#" v-if="document.filetype !== 'video'" @click="handleDocClick(document.id)" class="text-gray-800 text-hover-primary d-flex flex-column">
                                <div class="symbol symbol-100px m-4"><img :src="document.picto" alt="" /></div>
                                <div class="fs-5 fw-bolder" v-if="i18n && i18n.locale === 'fr'">{{ document.label }}</div>
                                <div class="fs-5 fw-bolder" v-if="i18n && i18n.locale === 'en'">{{ document.label_en }}</div>
                            </a>
                            <router-link v-else :to="'/drive/' + document.id + '/'">
                                <a href="" class="text-gray-800 text-hover-primary d-flex flex-column">
                                    <div class="symbol symbol-100px m-4"><img :src="document.picto" alt="" /></div>
                                    <div class="fs-5 fw-bolder" v-if="i18n && i18n.locale === 'fr'">{{ document.label }}</div>
                                    <div class="fs-5 fw-bolder" v-if="i18n && i18n.locale === 'en'">{{ document.label_en }}</div>
                                </a>
                            </router-link>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<style>
.drive-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding-bottom: 1em;
}

.elem {
    height: 100%;
    display: inline-block;
    padding: 0 0.75rem;
}

.card-body {
    white-space: normal;
}
</style>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import adminApi from "../core/services/AdminApi";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "drive",
    components: {},
    data() {
        return {
            userDocData: "" as any,
            base64Doc: "",
            filetype: "",
            fileURL: "",
            picto: "infography",
        };
    },
    setup() {
        const { t } = useI18n();
        const i18n = useI18n();
        setCurrentPageBreadcrumbs(t("pages.dashboard.drive"), []);
        return { t, i18n };
    },
    created() {
        adminApi.getUserDocumentation().then((data: any) => {
            this.userDocData = data.categories;
        });
    },
    methods: {
        handleDocClick(id: number) {
            adminApi.getDocument(id).then((data: any) => {
                this.base64Doc = data.document.document;
                this.filetype = data.document.filetype;

                const base64result = this.base64Doc.split(",")[1];
                const blob = this.b64toBlob(base64result, this.filetype);

                this.fileURL = URL.createObjectURL(blob);

                let a = document.createElement("a");
                a.target = "_blank";
                a.href = this.fileURL;
                a.click();
            });
        },
        b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
            const byteCharacters = atob(b64Data);
            const byteArrays = [] as Array<Uint8Array>;

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
        },
    },
});
</script>
